import React, { useEffect } from "react"

import { changeLanguage } from "i18next"

import aeFlag from "../images/country-flags/ae.svg"
import atFlag from "../images/country-flags/at.svg"
import auFlag from "../images/country-flags/au.svg"
import beFlag from "../images/country-flags/be.svg"
import bgFlag from "../images/country-flags/bg.svg"
import brFlag from "../images/country-flags/br.svg"
import chFlag from "../images/country-flags/ch.svg"
import cnFlag from "../images/country-flags/cn.svg"
import czFlag from "../images/country-flags/cz.svg"
import deFlag from "../images/country-flags/de.svg"
import dnFlag from "../images/country-flags/dn.svg"
import esFlag from "../images/country-flags/es.svg"
import fiFlag from "../images/country-flags/fi.svg"
import frFlag from "../images/country-flags/fr.svg"
import huFlag from "../images/country-flags/hu.svg"
import icFlag from "../images/country-flags/ic.svg"
import inFlag from "../images/country-flags/in.svg"
import itFlag from "../images/country-flags/it.svg"
import nlFlag from "../images/country-flags/nl.svg"
import noFlag from "../images/country-flags/no.svg"
import plFlag from "../images/country-flags/pl.svg"
import ptFlag from "../images/country-flags/pt.svg"
import rsFlag from "../images/country-flags/rs.svg"
import ruFlag from "../images/country-flags/ru.svg"
import skFlag from "../images/country-flags/sk.svg"
import swFlag from "../images/country-flags/sw.svg"
import trFlag from "../images/country-flags/tr.svg"
import gbFlag from "../images/country-flags/gb.svg"
import usFlag from "../images/country-flags/us.svg"
import zaFlag from "../images/country-flags/za.svg"

//import Layout from "../gatsby-theme-rothenberger/components/modules/Layout"
import Layout from "../@yumgmbh/gatsby-theme-yum-components/components/modules/Layout"

const globalWebsitesMapping =
  require("../../config/global-websites").globalWebsitesMapping

const globalBuildBadges = [
  {
    path: "/de-de/",
    badge:
      "https://api.netlify.com/api/v1/badges/55007390-23e9-455c-aa97-7314f6c258c5/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-de-develop/deploys",
  },
  {
    path: "/gb-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/17c814f3-531f-45a7-b7cd-5aba544cce6c/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-gb/deploys",
  },
  {
    path: "/fr-fr/",
    badge:
      "https://api.netlify.com/api/v1/badges/8b06219a-5bff-454d-847d-fdac25b283a6/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-fr/deploys",
  },
  {
    path: "/it-it/",
    badge:
      "https://api.netlify.com/api/v1/badges/fcc336a5-2759-4bff-9c5e-b41443e78105/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-it/deploys",
  },
  {
    path: "/es-es/",
    badge:
      "https://api.netlify.com/api/v1/badges/8d27cbab-9b96-4a7b-b8f0-5b5b2dc63789/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-es/deploys",
  },
  {
    path: "/pt-pt/",
    badge:
      "https://api.netlify.com/api/v1/badges/3c9d9943-46e5-4a7b-a6df-b645f9857067/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-pt/deploys",
  },
  {
    path: "/nl-nl/",
    badge:
      "https://api.netlify.com/api/v1/badges/436800f6-dfa1-4ef7-8be8-c1914294d2b6/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-nl/deploys",
  },
  {
    path: "/hu-hu/",
    badge:
      "https://api.netlify.com/api/v1/badges/1891f448-e26d-4cbf-bba5-60c99a9bc28b/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-hu/deploys",
  },
  {
    path: "/be-nl/",
    badge:
      "https://api.netlify.com/api/v1/badges/4118534d-69a0-4beb-842b-926073f8e80b/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-be/deploys",
  },
  {
    path: "/be-fr/",
    badge:
      "https://api.netlify.com/api/v1/badges/4118534d-69a0-4beb-842b-926073f8e80b/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-be/deploys",
  },
  {
    path: "/ch-de/",
    badge:
      "https://api.netlify.com/api/v1/badges/31993ca1-6d49-4d9b-b471-7d5c8b9fa7a2/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-ch/deploys",
  },
  {
    path: "/ch-fr/",
    badge:
      "https://api.netlify.com/api/v1/badges/31993ca1-6d49-4d9b-b471-7d5c8b9fa7a2/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-ch/deploys",
  },
  {
    path: "/ch-it/",
    badge:
      "https://api.netlify.com/api/v1/badges/31993ca1-6d49-4d9b-b471-7d5c8b9fa7a2/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-ch/deploys",
  },
  {
    path: "/sk-sk/",
    badge:
      "https://api.netlify.com/api/v1/badges/d3ac4be5-ee99-4d13-87cd-b47f3dcd58bd/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-sk/deploys",
  },
  {
    path: "/cz-cz/",
    badge:
      "https://api.netlify.com/api/v1/badges/95ce933d-a132-477e-832f-6049577a6e7e/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-cz/deploys",
  },
  {
    path: "/bg-bg/",
    badge:
      "https://api.netlify.com/api/v1/badges/87b5c078-d8be-4264-a93d-522f1f8434f8/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-bg/deploys",
  },
  {
    path: "/at-de/",
    badge:
      "https://api.netlify.com/api/v1/badges/38a3495b-97c9-44ad-b660-a5aebbc6c124/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-at/deploys",
  },
  {
    path: "/za-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/61d9d180-3749-404f-b366-232033599f88/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-za/deploys",
  },
  {
    path: "/pl-pl/",
    badge:
      "https://api.netlify.com/api/v1/badges/f94363e5-d14b-4b47-bfbd-3597f54fc227/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-pl/deploys",
  },
  {
    path: "/in-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/66e63096-2888-4430-bdfd-f41546b39fb4/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-in/deploys",
  },
  {
    path: "/ae-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/c7148724-b42c-4355-85a5-46fe43b77fe7/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-ae/deploys",
  },
  {
    path: "/tr-tr/",
    badge:
      "https://api.netlify.com/api/v1/badges/343239d9-f0aa-43c4-98c3-f51298b376f7/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-tr/deploys",
  },
  {
    path: "/br-pt/",
    badge:
      "https://api.netlify.com/api/v1/badges/6a568606-e6b7-4b08-a35d-34c9a6146cb7/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-br/deploys",
  },
  {
    path: "/us-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/57c4832d-0963-45a0-98b3-a71ad7fb3557/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-us/deploys",
  },
  {
    path: "/rs-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/47b88f6e-8bbd-45b1-ae0b-9eea74baf4fb/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-rs/deploys",
  },
  {
    path: "/au-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/1ed9132a-c2ab-4aab-b511-7fab6fbf4a9d/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-au/deploys",
  },
  {
    path: "/scandi-en/",
    badge:
      "https://api.netlify.com/api/v1/badges/71f0441a-a747-4aed-a40c-d1d4b0ba8ba9/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-scandi/deploys",
  },
  {
    path: "/",
    badge:
      "https://api.netlify.com/api/v1/badges/74c8c219-67d2-4567-a029-343418037d19/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-www/deploys",
  },
]

const flagMapping = {
  ae: aeFlag,
  at: atFlag,
  au: auFlag,
  be: beFlag,
  bg: bgFlag,
  br: brFlag,
  ch: chFlag,
  cn: cnFlag,
  cz: czFlag,
  de: deFlag,
  dn: dnFlag,
  es: esFlag,
  fi: fiFlag,
  fr: frFlag,
  hu: huFlag,
  ic: icFlag,
  in: inFlag,
  it: itFlag,
  nl: nlFlag,
  no: noFlag,
  pl: plFlag,
  pt: ptFlag,
  rs: rsFlag,
  ru: ruFlag,
  sk: skFlag,
  sw: swFlag,
  tr: trFlag,
  gb: gbFlag,
  us: usFlag,
  za: zaFlag,
}

const IndexPage = () => {
  const language = "en"
  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)
    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language

        setTimeout(() => {
          window.klaro.render({
            ...window.klaroConfig,
            ...{ lang: language },
          })
          if (window.klaro.getManager) {
            let manager = window.klaro.getManager()
            manager.applyConsents()
          }
        }, 50)
      }
    }
  }, [language])

  let buildWebsites = []
  buildWebsites.push({
    title: "Global - English",
    badgeSrc:
      "https://api.netlify.com/api/v1/badges/74c8c219-67d2-4567-a029-343418037d19/deploy-status",
    netlifyUri: "https://app.netlify.com/sites/ro-www/deploys",
  })
  globalWebsitesMapping.forEach((region) => {
    buildWebsites.push({ title: region.region })
    region.websites.forEach((website) => {
      const badgeItem = globalBuildBadges.find((item) => {
        return item.path === website.path
      })
      if (badgeItem) {
        website.badgeSrc = badgeItem.badge
        website.netlifyUri = badgeItem.netlifyUri
        buildWebsites.push(website)
      }
    })
  })

  return (
    <>
      <Layout>
        <section>
          <div className="py-block block-w-container">
            <h1 className="headline">Build-Status-Übersicht</h1>
            <div className="grid grid-flow-col grid-rows-40 md:grid-rows-20 lg:grid-rows-10">
              {buildWebsites.map((website) => {
                return (
                  <div key={website.title}>
                    {website.netlifyUri ? (
                      <a
                        className="block px-2 py-3 hover:text-primary"
                        href={website.netlifyUri}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {website.countryCode && (
                          <>
                            <img
                              src={flagMapping[website.countryCode]}
                              alt={website.title}
                              className="inline-block mr-2 align-middle"
                            />{" "}
                          </>
                        )}
                        {website.title}
                        <br />
                        <img
                          src={website.badgeSrc}
                          alt={website.title}
                          className="inline-block mr-2 align-middle"
                        />{" "}
                      </a>
                    ) : (
                      <span className="block px-2 py-3 mt-6 font-bold">
                        {website.title}:
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default IndexPage
